import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "@nextui-org/react"

import { ModalProps } from "@nextui-org/react"

interface Props extends ModalProps {
  headerContent?: React.ReactNode
  footerContent?: React.ReactNode
  isDismissible?: boolean
  onOpenChange: (open: boolean) => void
}

export default function CustomModal({
  children,
  isOpen,
  onOpenChange,
  headerContent,
  footerContent,
  scrollBehavior,
  className,
  isDismissable = true,
  ...props
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      placement={props.placement ?? "auto"}
      onOpenChange={open => {
        setTimeout(() => {
          onOpenChange(open as boolean)
        }, 10)
      }}
      size="5xl"
      scrollBehavior={scrollBehavior}
      isKeyboardDismissDisabled={!isDismissable}
      isDismissable={isDismissable}
      backdrop="blur"
      classNames={{
        base: className,
        closeButton: "text-[28px] text-gray-08 m-2",
        backdrop: "bg-[rgba(23,29,35,0.50)]",
      }}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0,
            },
          },
          exit: {
            y: 0,
            opacity: 0,
            transition: {
              duration: 0,
            },
          },
        },
      }}
      {...props}
    >
      <ModalContent>
        {headerContent && (
          <ModalHeader className="mb-0 pb-0 font-normal max-sm:px-4">
            {headerContent}
          </ModalHeader>
        )}
        <ModalBody className="max-sm:px-4">
          <div className="mt-6">{children}</div>
        </ModalBody>
        {footerContent ? (
          <ModalFooter className="block max-sm:px-4">{footerContent}</ModalFooter>
        ) : (
          <div className="py-2"></div>
        )}
      </ModalContent>
    </Modal>
  )
}
