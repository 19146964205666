import { formatNumber } from "@/utils/formats"
import { Policy } from "@/store/subscriptionStore"
import { Button } from "@nextui-org/react"

const FEATURES = {
  POLICY_FREE: [
    "월 최대 발행량 3건",
    "최대 사용 인원 1명",
    "매입세금계산서 관리",
    "거래처 관리",
    "세금계산서 보관",
  ],
  POLICY_BASIC: [
    "월 최대 발행량 20건",
    "최대 사용 인원 2명",
    "카카오톡 알림",
    "예약발행",
    "권한 설정",
  ],
  POLICY_PLUS: [
    "월 최대 발행량 제한 없음",
    "최대 사용 인원 5명",
    "::(초과시 1인당 월7,000원 추가)",
    "매출세금계산서 관리",
    "발행정보 요청서",
    "대량발행",
  ],
  POLICY_ENTERPRISE: ["기업 맞춤형 기능 개발", "전담 담당자 배정"],
}

type Props = {
  policyKey: string
  policy: Policy
  isDisabled?: boolean
  buttonText: string
  onClick: () => void
}

export default function PlanCard({
  policyKey,
  policy,
  isDisabled,
  buttonText,
  onClick,
}: Props) {
  const features = FEATURES[policyKey]

  return (
    <div className="flex flex-col rounded-lg border p-6">
      <div className="relative flex h-[94px] flex-col justify-between">
        <div className="font-semibold text-gray-09">{policy.displayName}</div>
        {policyKey === "POLICY_PLUS" && (
          <div className="absolute -right-2 -top-2 inline-block rounded border border-[#FF6600] px-[6px] py-[2px] text-[12px] text-primary">
            가장 인기!
          </div>
        )}
        <div>
          <div className="text-sm text-gray-08 line-through">
            {policy.originalPrice ? formatNumber(policy.originalPrice) : ""}
          </div>
          {policyKey === "POLICY_ENTERPRISE" ? (
            <div className="text-secendary">
              <span className="text-[24px] font-medium">실시간 상담</span>
            </div>
          ) : (
            <div className="text-secendary">
              <span className="text-[24px] font-medium">
                {formatNumber(policy.displayPrice)}
              </span>
              /월
            </div>
          )}
        </div>
      </div>

      <hr className="my-5" />

      <ul className="check-list mb-8">
        {features.map((feature, index) => {
          if (feature.startsWith("::")) {
            return (
              <li key={index} className="sub">
                {feature.slice(2)}
              </li>
            )
          }
          return <li key={index}>{feature}</li>
        })}
      </ul>

      {policyKey === "POLICY_ENTERPRISE" ? (
        <Button
          color="primary"
          className="mt-auto w-full bg-[#60666C]"
          onClick={() => onClick()}
        >
          상담
        </Button>
      ) : (
        <Button
          color="primary"
          className="mt-auto w-full"
          isDisabled={isDisabled}
          onClick={() => onClick()}
        >
          {buttonText}
        </Button>
      )}
    </div>
  )
}
